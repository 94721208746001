import { GetStaticProps, GetStaticPaths } from 'next'
import uniqBy from 'lodash.uniqby'

import {
  fetcher,
  fetchPromotion,
  fetchSection,
  fetchCollection,
  fetchArticleSection,
  fetchMenu,
  FetcherConfig,
  cacheStore
} from '../fetchers'
import { fetchPostBySlug, fetchPostCollectionIds, fetchRelatedPostUrls } from '../fetchers/post'
import { fetchGlobalSettings, fetchHomeData } from '../fetchers/home'

import { MenuValue, Promotion, Post, SectionsResults, KeywordGroupGroups } from '../types'

import Detail from '../containers/Detail'
import { autoSEOComponentPage } from '../utils/seo'

const DetailPage = autoSEOComponentPage(Detail)

export default DetailPage

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
  const { result } = await fetchHomeData()
  const homePosts = result.map(item => item.data.posts || []).flat()
  const paths = homePosts
    .filter(post => post.slug)
    .map(post => {
      return {
        params: {
          slug: post.slug
        }
      }
    })

  return {
    paths,
    fallback: true
  }
}

export const getStaticProps: GetStaticProps = async context => {
  let promotions: { [key: string]: Promotion[] } = {}
  let error = false
  let post: Post
  let result: SectionsResults = []
  let relatedPosts: Post[]
  let headerMenu: { [key: string]: MenuValue } = {}
  let keywords = ''
  let keywordGroups: KeywordGroupGroups = []
  let collectionIds: number[] = []

  const slug =
    typeof context.params.slug === 'string'
      ? encodeURI(context.params.slug as string)
      : encodeURI(context.params.slug[0])

  const mustRedirectPaths = ['sections', 'collections', 'tags', 'authors', '[slug]', 'sign-in']

  if (mustRedirectPaths.includes(slug)) {
    return {
      redirect: {
        destination: `/`,
        statusCode: 301
      },
      props: {
        error: true
      }
    }
  }

  const defaultFetcherConfig: FetcherConfig = {
    cache: cacheStore
  }

  try {
    const postResult = await fetcher(fetchPostBySlug(slug), defaultFetcherConfig)
    if (postResult.redirect) {
      return {
        redirect: {
          destination: `${process.env.NEXT_PUBLIC_HOST}/${postResult.slug}`,
          statusCode: 301
        }
      }
    }
    post = postResult
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e)
    return {
      notFound: true,
      props: {
        error: true
      }
    }
    error = true
  }

  try {
    const responses = await Promise.all([
      fetcher(fetchPromotion(), defaultFetcherConfig),
      fetcher(fetchArticleSection(), defaultFetcherConfig),
      fetcher(fetchMenu(), defaultFetcherConfig),
      fetchGlobalSettings(),
      fetchPostCollectionIds(post.id)
    ])

    promotions = responses[0]

    headerMenu = responses[2]['primary-menu']?.menu || {}

    const globalSettigns = responses[3]

    collectionIds = responses[4]?.collections || []

    if (globalSettigns.keywordGroups) {
      keywordGroups = globalSettigns.keywordGroups
    }

    const sectionsResult = responses[1]

    const fetchRelatedUrls = fetchRelatedPostUrls(post)
    const fetcherRelated = []
    fetchRelatedUrls.map(url => fetcherRelated.push(fetcher(url, defaultFetcherConfig)))

    const fetchersSection =
      sectionsResult.sections?.map(s => {
        if (s.sectionId && typeof s.sectionId === 'number') {
          return fetcher(fetchSection({ withPosts: true, id: s.sectionId }), defaultFetcherConfig)
        }
        if (s.collectionId && typeof s.collectionId === 'number') {
          return fetcher(fetchCollection({ withPosts: true, id: s.collectionId }), defaultFetcherConfig)
        }
        return new Promise(resolve => {
          resolve(undefined)
        })
      }) || []

    const [data, relatedPostsData] = await Promise.all([Promise.all(fetchersSection), Promise.all(fetcherRelated)])

    relatedPosts = uniqBy((relatedPostsData || []).flat(), 'id')
    result = (
      sectionsResult.sections?.map((s, index) => {
        return {
          section: s,
          data: data[index]
        }
      }) || []
    ).filter(d => {
      return d.data
    })

    result = [
      {
        section: {
          layout: 'Related Post',
          sectionId: null,
          collectionId: null,
          viewAll: false
        },
        data: relatedPosts.slice(0, 5)
      },
      ...result
    ]
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e)
    error = true
  }

  if (post && post.taxonomies && post.taxonomies.post_tag) {
    keywords = post.taxonomies.post_tag.map(tag => tag.name).join(',')
  }

  return {
    revalidate: 60,
    props: {
      seo: post?.yoast_head || '',
      keywords,
      headerMenu,
      is404: false,
      post,
      result,
      promotions,
      keywordGroups,
      collectionIds,
      error: error
    }
  }
}
